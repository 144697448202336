import React, { useState } from "react";
import searchIcon from "../../assets/search-icon.svg";
import Tuning from "../../assets/Tuning.svg";
import { useTranslation } from "react-i18next";
import clockIn from "../../assets/clock-in.svg";
import Loader from "../../components/Loader";
import moment from 'moment';
interface VisitItemProps {
  visit: any;
}

const VisitItem: React.FC<VisitItemProps> = ({ visit }) => {

  type Status = "SUCCESSFUL" | "PRODUCTIVE_VISIT" | "FAILED";

  const getStatusComp = (status:Status): any => {
    switch (status) {
      case "SUCCESSFUL":
        return <div className="bg-cugreen w-max px-2 p-1 text-white rounded-sm text-8">Successful Visit</div>;
      case "PRODUCTIVE_VISIT":
        return <div className="bg-orange w-max px-2 p-1 text-white rounded-sm text-8">Productive Visit</div>;
      case "FAILED":
        return <div className="bg-cugreen px-2 p-1 text-white rounded-sm text-8">SUCCESSFUL</div>;
      default:
        return "black";
    }
  };

  return (
    <div className=" pl-4 pt-4 border-l-2 border-black relative ml-3">
      <div className="absolute left-[-6px] top-[60%] transform -translate-y-1/2 bg-black rounded-full w-3 h-3"></div>
      <div className="flex items-center justify-between p-2 border rounded-md shadow-sm bg-white">
        <div className=" items-center space-x-1 w-full">
          {/* <img src={MapIcon} alt="Map Icon" className="w-6 h-6 text-gray-500" /> */}
          <div className="flex gap-2 justify-between w-full">
            <div className="flex gap-2 w-full">
              <div className="bg-black rounded-full w-full max-w-[30px] h-[30px] text-white flex items-center justify-center">
              {visit.sequence}
              </div>
              <div className="w-full">
                <div className="font-bold	text-16">{visit.sequence}</div>
                <div className="font-medium	text-13">{visit.outlet_name_en}</div>
              </div>
            </div>
            <div>{getStatusComp(visit.outcome_status)} </div>
          </div>
          <div className="border-t-2 flex gap-2 justify-between p-2">
            <div className="text-13">
              Check out : <b>0{visit.check_in_at}</b>
            </div>
            <div className="text-13">
              Check out : <b>{visit.check_out_at}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface VisitListProps{
  employeeSchedule:any,
  loader:boolean
}

const VisitList: React.FC<VisitListProps> = ({employeeSchedule,loader}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");

  const filteredVisits = employeeSchedule.customer_visits?.filter((visit:any) =>
    visit.outlet_name_en.toLowerCase().includes(searchTerm.toLowerCase())
  );


  function convertTo12HourFormat(time: string): string {
    // let [hours, minutes, seconds] = time?.split(':').map(Number);
    // let period = hours >= 12 ? 'PM' : 'AM';

    // hours = hours % 12 || 12; 
    // return `${hours}:${minutes.toString().padStart(2, '0')} ${period}`;
    return time
}


  return (
    <div className="">
      <div className="relative flex items-center p-2 pl-4 pr-4 border-b-2">
        <span className="absolute left-7">
          <img src={searchIcon} alt="Search Icon" className="h-5 w-5" />
        </span>
        <input
          type="text"
          placeholder={t("VisitList.search_salesmen")}
          className="pl-10 p-2 border rounded w-[85%]"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className="ml-4" aria-label="Settings Button">
          <img src={Tuning} alt="Settings Icon" className="h-5 w-5" />
        </button>
      </div>

      <div className="pl-4 pr-4 pt-4">
        <div className="border-2 w-full min-h-[40px] flex justify-between p-2 ">
          <div className="flex gap-2 items-center font-bold	text-16">
            <img src={clockIn} alt=""/>
            Clock In
          </div>
          <div className="font-bold	">{moment(employeeSchedule?.employee_details?.clock_in , "HH:mm:ss.SSSSSS").format('hh:mm A')}</div>
        </div>
        {loader && <Loader/>}
        <div className=" overflow-auto h-auto md:h-[calc(100vh-244px)]">
          {filteredVisits?.length > 0 && !loader ? (
            filteredVisits?.map((visit:any, index:number) => (
              <VisitItem
                key={index}
                visit={visit}
              />
            ))
          ) : (
            <p className="text-gray-500">{t("VisitList.no_visits_found")}</p>
          )}
          <div className="border-2 w-full min-h-[40px] flex justify-between p-2">
            <div className="flex gap-2 items-center font-bold	text-16">
              <img src={clockIn} alt="clock in"/>
              Clock out
            </div>
            <div className="font-bold	">{moment(employeeSchedule?.employee_details?.clock_out ,"HH:mm:ss.SSSSSS").format('hh:mm A')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitList;
