import React, { useState } from "react";
import searchIcon from "../../assets/search-icon.svg";
import infoCircleIcon from "../../assets/Info-Circle.svg";
import { useTranslation } from "react-i18next";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const InfoIcon = () => (
  <img src={infoCircleIcon} alt="Info Icon" className="h-5 w-5" />
);
const MoreIcon = () => <span>⋮</span>;

interface SalesmanListProps {
  selctedEmp: any;
  handleEmpSected: any;
  employeeData: any;
  isDropdownOpen: any;
  setIsDropdownOpen: any;
  selectedEmpPopup: any;
  handleSelectedEmpIcon: any;
  infoIconClick: any;
  activePopupIndex: any;
  markarData: any;
}

const SalesmanList: React.FC<SalesmanListProps> = ({
  selctedEmp,
  handleEmpSected,
  employeeData,
  isDropdownOpen,
  setIsDropdownOpen,
  selectedEmpPopup,
  infoIconClick,
  activePopupIndex,
  handleSelectedEmpIcon,
  markarData,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const filteredSalespeople = employeeData?.filter((person: any) =>
    person.name_eng.toLowerCase().includes(searchTerm.toLowerCase())
  );

  type Status = "AT_LOCATION" | "IDLE" | "ENROUTE";

  const getStatusColor = (status: Status): string => {
    switch (status) {
      case "AT_LOCATION":
        return "cugreen";
      case "IDLE":
        return "customorange";
      case "ENROUTE":
        return "purple";
      default:
        return "black";
    }
  };

  return (
    <>
      <div className="relative  flex items-center pb-4 mb-1 w-full p-4">
        <span className="absolute left-6">
          <img src={searchIcon} alt="Search Icon" className="h-5 w-5" />
        </span>
        <input
          type="text"
          placeholder={t("SalesmanList.search")}
          className="pl-10 p-2 border rounded w-full"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="border-b-2"></div>
      <div className="block md:hidden p-4">
        <button
          className="w-full flex items-center justify-between p-2 border rounded-md shadow-sm  font-semibold"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <span>{"All employee"}</span>
          <span className="ml-2">
            {isDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
          </span>
        </button>
        {selctedEmp?.emp_id && (
          <div className="pt-3">
            <div
              className={`bg-${getStatusColor(
                selctedEmp.activity_status
              )} relative flex items-center justify-between p-2 border rounded-md shadow-sm cursor-pointer hover:bg-[#00C48C]/50 "`}
            >
              <div className="flex items-center space-x-2 cursor-pointer">
                <span className={`rounded w-1.5 h-12 bg-white`} />
                <div>
                  <p className={`text-white font-bold	text-16`}>
                    {selctedEmp.name_eng}
                  </p>
                  <p className={`text-white font-medium`}>
                    {selctedEmp.emp_id}
                  </p>
                </div>
              </div>
              <div className="flex  flex-col flex-start justify-center min-w-[30px]">
                <button
                  className="p-1"
                  aria-label="Info Icon"
                  onClick={() => handleSelectedEmpIcon()}
                >
                  <InfoIcon />
                </button>
                <button
                  onClick={() => console.log("More Icon clicked")}
                  className="p-1"
                  aria-label="More Icon"
                >
                  <MoreIcon />
                </button>
              </div>
              {selectedEmpPopup && (
                <div className="absolute right-10 top-0 mr-2 w-48 p-4 bg-white border rounded-lg z-50">
                  {/* Arrow pointing to the icon */}
                  <div className="absolute right-[-8px] top-4 rotate-180   transform -translate-y-1/4 w-0 h-0 border-t-8 border-t-transparent border-b-8 border-b-transparent border-r-8 border-r-lightgrey z-50"></div>

                  {/* Popup content */}
                  <p>
                    This is the info content that appears when the icon is
                    clicked.
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="pl-4 pr-4 ">
        <ul
          className={`${
            isDropdownOpen
              ? "block absolute bg-white z-50 w-[92%] overflow-auto"
              : "hidden  h-[calc(100vh-150px)] "
          }md:block space-y-4 h-[300px] overflow-y-auto	overflow-x-hidden	 mt-2`}
        >
          {filteredSalespeople.map((person: any, index: any) => (
            <li
              key={index}
              className={`${
                selctedEmp.emp_id === person.emp_id
                  ? `relative bg-${getStatusColor(person.activity_status)}`
                  : "relative"
              } flex items-center gap-1  p-2 border rounded shadow-sm hover:bg-[#00C48C]/50 "`}
            >
              <div
                className="flex items-center space-x-2 cursor-pointer w-full"
                onClick={() => handleEmpSected(person)}
              >
                <span
                  className={`rounded w-1.5 h-12 bg-${
                    selctedEmp.emp_id === person.emp_id
                      ? "white"
                      : getStatusColor(person.activity_status)
                  }`}
                />
                <div>
                  <p
                    className={`${
                      selctedEmp.emp_id === person.emp_id
                        ? "text-white font-bold text-16"
                        : "text-black font-bold text-16"
                    }`}
                  >
                    {person.name_eng}
                  </p>
                  <p
                    className={`"font-bold text-16" ${
                      selctedEmp.emp_id === person.emp_id
                        ? "text-white font-medium"
                        : "text-black font-medium"
                    }`}
                  >
                    {person.emp_id}
                  </p>
                </div>
              </div>

              {/* Container around the icon and popup */}
              <div className=" flex  flex-col flex-start justify-center min-w-[30px]">
                <button
                  onClick={() => infoIconClick(index, person.emp_id)}
                  className="p-1"
                  aria-label="Info Icon"
                >
                  <InfoIcon />
                </button>
                <button
                  onClick={() => console.log("Info Icon clicked")}
                  className="p-1"
                  aria-label="Info Icon"
                >
                  <MoreIcon />
                </button>

                {/* Popup relative to the Info Icon */}
              </div>
              {activePopupIndex === index && (
                <div className="absolute right-10 top-0 mr-2 w-55 p-4 bg-white border rounded-lg z-50">
                  {/* Arrow pointing to the icon */}
                  <div className="absolute right-[-8px] top-4 rotate-180   transform -translate-y-1/4 w-0 h-0 border-t-8 border-t-transparent border-b-8 border-b-transparent border-r-8 border-r-lightgrey z-50"></div>

                  {/* Popup content */}
                  <div className="pt-2">
                    <p>
                      Total traveling till now :
                      {markarData?.total_traveling_till_now} Km
                    </p>
                    <p>Total visits till now : {markarData?.total_visits}</p>
                    <p>Successful visits : {markarData?.successful_visits}</p>
                    <p>Failed Visits : {markarData?.failed_visits} </p>
                    <p>Productive Visit : {markarData?.productive_visits}</p>
                  </div>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default SalesmanList;
