import React, { useState, useEffect, useRef } from "react";
import DashboardPage from "../../layout/index";
import SalesmanList from "./Salesman-list";
import SalesmanOption from "./SalesmanOption";
import GoogleMap from "../../components/GoogleMap";
import getEmployeeList, {
  getEmployeeSchedule,
  getMarkerInfo,
  getStream,
  getStreamHistory,
  getStreamList,
} from "../../api/mapView";
import {
  formatCurrentStartDate,
  formatCurrentEndDate,
  getUserInfo,
} from "../../utility";
import Cookies from "js-cookie";
interface Employee {
  emp_id: string;
  name: string;
  age: number;
  department: string;
}

export default function Salesman() {
  const [selctedEmp, setSelectedEmp] = useState<any>("All employees");
  const [employeeData, setEmployeeData] = useState<Employee[]>([]);
  const [employeeSchedule, setEmployeeSchedule] = useState<Employee[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState<any | null>(null);
  const [startDate, setStartDate] = useState<any | null>();
  const [endDate, setEndDate] = useState<any | null>(null);
  const [selectedDate, setSelectedDate] = useState<any>();
  const [loader, setLoader] = useState(false);
  const [locationData, setLocationData] = useState<any | null>([]);
  const [historyData, setHistoryData] = useState<any | null>(null);
  const [markarData, setMarkarData] = useState<any | null>(null);
  const [selectedEmpPopup, setSelectedEmpPopup] = useState<boolean>(false);
  const [selectedRoute, setSelectedRoute] = useState<string>("");
  const [actualData, setActualdata] = useState<any>("");

  const handleRouteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedRoute === e.target.value) {
      setSelectedRoute("");
    } else {
      setSelectedRoute(e.target.value);
      setSelectedDate(null);
    }
  };

  const formatDate = (date: Date) => {
    return date.toISOString().split("T")[0];
  };

  useEffect(() => {
    const today = new Date();
    setDateRange(today);
    getEmployee();
  }, []);

  const getStreamData = async (emp_data: any) => {
    const empIds = emp_data.map((emp: any) => emp.emp_id);
    const user_info = getUserInfo();
    const data = {
      emp_id: user_info.emp_id,
      requested_emp_ids: empIds,
    };
    await getStream(data)
      .then((res) => {
        console.log(res);
        getStreamDataList(res.data.stream_id);
        getEventSource(res.data.stream_id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleStreamResponse = (data: {
    dev_id: string;
    latitude: number;
    longitude: number;
    platform: string;
  }) => {
    const { latitude, longitude } = data;

    const existingMarkerIndex = locationData.findIndex(
      (marker: any) => marker.dev_id === data.dev_id
    );

    if (existingMarkerIndex !== -1) {
      const updatedMarkers = [...locationData];
      updatedMarkers[existingMarkerIndex] = {
        ...updatedMarkers[existingMarkerIndex],
        latitude: data.latitude,
        longitude: data.longitude,
      };
      setLocationData(updatedMarkers);
    } else {
      setLocationData([
        ...locationData,
        {
          position: { lat: Number(latitude), lng: Number(longitude) },
          label: data.dev_id,
          status: "enroute", // todo :  make this change as its come form backends
        },
      ]);
    }
  };

  const getEventSource = (streamId: number) => {
    const token = Cookies.get("token");

    const env = process.env.REACT_APP_ENV;
    let baseUrlGps = null;

    if (env === "development") {
      baseUrlGps = process.env.REACT_APP_DEV_GPS_API_URL;
    } else if (env === "stag") {
      baseUrlGps = process.env.REACT_APP_STAGING_GPS_API_URL;
    } else if (env === "prod") {
      baseUrlGps = process.env.REACT_APP_PROD_GPS_API_URL;
    } else {
      console.error("No valid environment specified");
    }

    const eventSource = new EventSource(
      `${baseUrlGps}/gps/stream/${streamId}?token=${token}`
    );
    eventSource.onmessage = (event: any) => {
      handleStreamResponse(event.data);
    };

    eventSource.onerror = (err) => {
      console.error("EventSource error:", err);
      eventSource.close();
    };
  };

  const getStreamDataHistory = async (data: any) => {
    await getStreamHistory(data)
      .then((res) => {
        formattedHistoryData(res.data?.events);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formattedHistoryData = (data: any) => {
    const ks = data.map((item: any) => ({
      location: {
        lat: parseFloat(item.location.lat),
        lng: parseFloat(item.location.lng),
      },
    }));
    setActualdata({
      origin: { lat: 24.7129, lng: 46.677 },
      destination: { lat: 25.9184, lng: 45.3517 },
      waypoints: ks,
    });
  };

  const getStreamDataList = async (res: any) => {
    await getStreamList(res)
      .then((res) => {
        console.log(res, "strem");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setDateRange = (date: any) => {
    const formattedToday = formatDate(date);
    setSelectedDate(formattedToday);

    setStartDate(formatCurrentStartDate(date));
    setEndDate(formatCurrentEndDate(date));
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = new Date(e.target.value);

    if (!isNaN(selectedDate.getTime())) {
      setStartDate(formatCurrentStartDate(selectedDate));
      setEndDate(formatCurrentEndDate(selectedDate));

      const formattedToday = formatDate(selectedDate);
      setSelectedDate(formattedToday);

      getEmpSchedule(selctedEmp, {
        start_date: formatCurrentStartDate(selectedDate),
        end_date: formatCurrentEndDate(selectedDate),
      });

      const data = {
        requested_emp_ids: [selctedEmp.emp_id],
        start_time: formatCurrentStartDate(selectedDate),
        end_time: formatCurrentEndDate(selectedDate),
      };
      getStreamDataHistory(data);
    }
  };

  // const locations = [
  //   {
  //     position: { lat: 24.7115, lng: 46.674 },
  //     label: "Kingdom Tower",
  //     status: "idle",
  //     // total_travelled:'',
  //     // total_visit:'',
  //     // failed_visit:'',
  //     // productive_visit:'',
  //     // successful_visit:''
  //   },
  //   {
  //     position: { lat: 24.6926, lng: 46.685 },
  //     label: "Al Faisaliyah Center",
  //     status: "online",
  //   },
  //   {
  //     position: { lat: 24.7537, lng: 46.6535 },
  //     label: "Riyadh Park",
  //     status: "idle",
  //   },
  //   {
  //     position: { lat: 24.6769, lng: 46.7322 },
  //     label: "Riyadh Zoo",
  //     status: "idle",
  //   },
  //   {
  //     position: { lat: 24.6477, lng: 46.7096 },
  //     label: "National Museum of Saudi Arabia",
  //     status: "online",
  //   },
  //   {
  //     position: { lat: 24.6319, lng: 46.7144 },
  //     label: "Al Masmak Fortress",
  //     status: "enroute",
  //   },
  //   {
  //     position: { lat: 24.6894, lng: 46.6196 },
  //     label: "Diplomatic Quarter",
  //     status: "idle",
  //   },
  //   {
  //     position: { lat: 24.5932, lng: 46.6914 },
  //     label: "Wadi Hanifah",
  //     status: "idle",
  //   },
  //   {
  //     position: { lat: 24.9064, lng: 45.9926 },
  //     label: "Edge of the World",
  //     status: "idle",
  //   },
  //   {
  //     position: { lat: 24.7719, lng: 46.6527 },
  //     label: "King Abdullah Financial District",
  //     status: "enroute",
  //   },
  // ];

  const directionsData = {
    origin: { lat: 24.7136, lng: 46.6753 },
    destination: { lat: 24.633, lng: 46.7167 },
    waypoints: [{ location: { lat: 24.7743, lng: 46.7386 } }],
  };

  const plannedData = {
    origin: { lat: 24.7136, lng: 46.6753 },
    destination: { lat: 25.92, lng: 45.35 },
    waypoints: [
      { location: { lat: 24.7743, lng: 46.7386 } },
      { location: { lat: 25.0, lng: 46.55 } },
    ],
  };

  const selectedData = {
    origin: { lat: 24.7136, lng: 46.6753 },
    destination: { lat: 24.633, lng: 46.7167 },
    waypoints: [{ location: { lat: 24.7743, lng: 46.7386 } }],
  };

  const handleEmpSected = (person: any) => {
    if (selctedEmp.emp_id === person.emp_id) {
      setSelectedEmp("All employees");
      setIsDropdownOpen(false);
      return;
    } else {
      setSelectedEmp(person);
      setIsDropdownOpen(false);
      const params = {
        start_date: startDate,
        end_date: endDate,
      };
      getEmpSchedule(person, params);
      const data = {
        requested_emp_ids: [person.emp_id],
        start_time: formatCurrentStartDate(new Date(selectedDate)),
        end_time: formatCurrentEndDate(new Date(selectedDate)),
      };
      getStreamDataHistory(data);
    }
  };

  const getEmpSchedule = async (person: any, params: any) => {
    setLoader(true);
    const sub_id = person.emp_id;
    await getEmployeeSchedule(sub_id, params)
      .then((res) => {
        setEmployeeSchedule(res.data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };

  const getMarkerInfoData = async (emp_id: any) => {
    const params = {
      start_date: formatCurrentStartDate(new Date(selectedDate)),
      end_date: formatCurrentEndDate(new Date(selectedDate)),
    };
    await getMarkerInfo(emp_id, params)
      .then((res) => {
        setMarkarData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEmployee = async () => {
    await getEmployeeList()
      .then((res) => {
        setEmployeeData(res.data);
        getStreamData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [activePopupIndex, setActivePopupIndex] = useState<number | null>(null);

  const handleSelectedEmpIcon = () => {
    setSelectedEmpPopup(!selectedEmpPopup);
    if (!selectedEmpPopup) {
      getMarkerInfoData(selctedEmp.emp_id);
    }
  };

  const infoIconClick = (index: number, id: any) => {
    setActivePopupIndex(activePopupIndex === index ? null : index);
    if (activePopupIndex !== index) {
      getMarkerInfoData(id);
    }
  };

  const handleMarkerClick = (marker: any) => {
    setMarkarData(null);
    setSelectedMarker(marker);
    getMarkerInfoData(marker?.emp_id);
  };

  return (
    <DashboardPage>
      <div className="flex  flex-col md:flex-row h-[calc(100vh-54px)] ">
        <div className="w-full md:w-[25%]  md:h-full ">
          <SalesmanList
            selctedEmp={selctedEmp}
            handleEmpSected={handleEmpSected}
            employeeData={employeeData}
            isDropdownOpen={isDropdownOpen}
            setIsDropdownOpen={setIsDropdownOpen}
            selectedEmpPopup={selectedEmpPopup}
            handleSelectedEmpIcon={handleSelectedEmpIcon}
            infoIconClick={infoIconClick}
            activePopupIndex={activePopupIndex}
            markarData={markarData}
          />
        </div>
        {selctedEmp !== "All employees" ? (
          <>
            <div className="border-none md:border-solid  md:border-l-2 md:border-r-2 h-full w-full md:w-[50%] p-4 min-h-[320px]">
              <GoogleMap
                showDirections={true}
                directionsData={directionsData}
                plannedData={plannedData}
                actualData={actualData}
                selectedData={selectedData}
                selectedRoute={selectedRoute}
                handleRouteChange={handleRouteChange}
                markarData={markarData}
              />
            </div>
            <div className="w-full md:w-[30%] h-full ">
              <SalesmanOption
                employeeSchedule={employeeSchedule}
                handleDateChange={handleDateChange}
                selectedDate={selectedDate}
                loader={loader}
              />
            </div>
          </>
        ) : (
          <>
            <div className="border-l-2 min-h-[420px] w-full md:w-[78%] md:h-full p-4 h-[320px]">
              <GoogleMap
                showMarkers={true}
                markersData={locationData}
                setSelectedMarker={setSelectedMarker}
                selectedMarker={selectedMarker}
                handleMarkerClick={handleMarkerClick}
              />
              {selectedMarker && (
                <div className="md:hidden w-full bg-culightgrey h-auto p-6 mt-4 border border-gray-500 rounded">
                  <div className="text-16 font-bold">
                    {selectedMarker?.label}
                  </div>
                  <div className="pt-2">
                    <p>
                      Total traveling till now :
                      {markarData?.total_traveling_till_now} Km
                    </p>
                    <p>Total visits till now : {markarData?.total_visits}</p>
                    <p>Successful visits : {markarData?.successful_visits}</p>
                    <p>Failed Visits : {markarData?.failed_visits} </p>
                    <p>Productive Visit : {markarData?.productive_visits}</p>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </DashboardPage>
  );
}
