import React, { useState, FormEvent , useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/logo.svg";
import Button from "../../components/LoaderButton";
import URL_MAPPING from "../../routes/constants";
import ImageSection from "./imageSection";
import { resetPassLink, verifyMail } from "../../api/login";
import LanguageSelector from "../../components/LangSelector";
import { useTranslation } from "react-i18next";

const SignUp: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [optScreen, setOtpScreen] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const { isForgetPass } = location.state || {};

  const handleSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!username) {
      return setError("Please enter an email address");
    } else if (!emailRegex.test(username)) {
      return setError("Please enter a valid email");
    } else {
      setError("");
    }

    setLoader(true);
    await verifyMail({ email: username })
      .then((res:any) => {
        if (isForgetPass === false && (res?.data?.mfa_enabled && res?.data?.password_enabled)) {
          setError('User already registered')
        }else{
          getResetPassLink();
        }

      })
      .catch((error) => {
        setError(error?.response?.data?.detail?.message || "error");
      });

    setLoader(false);
  };

  const getResetPassLink = async () => {
    await resetPassLink({ email:username })
      .then((res) => {
        setOtpScreen(true);
      })
      .catch((error) => {
        setError(error?.response?.data?.detail?.message || "error");
      });
  };

 
  return (
    <>
      <div className="flex h-screen flex-col md:flex-row">
        <div className="md:w-1/2 flex-col relative w-full h-[120px] md:h-full">
          <ImageSection />
        </div>

        <div className="relative w-full md:w-1/2 flex flex-col justify-center items-center bg-white md:p-24 sm:p-14 p-10">
          <div className="mb-8">
            <img src={logo} alt="Logo" className="h-18" />
          </div>
          <div className="absolute top-[-12%] md:top-10 right-[13%]"><LanguageSelector/></div>
          {optScreen === false ? (
            <>
              <h2 className="text-2xl font-bold mb-6 text-purple">
                {isForgetPass ? "Forgot Password" : "Sign Up"}
              </h2>
              <div className="mb-4 w-full">
                <label className="block text-gray-700 text-13 font-semibold">
                {t('SignUp.email')}
                </label>
                <input
                  type="text"
                  value={username}
                  placeholder= {t('SignUp.enterEmail')}
                  onChange={(e) => setUsername(e.target.value)}
                  className="text-13 w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 bg-lightgrey h-12"
                />
              </div>
              <div className="mb-4 w-full text-red-600 text-sm text-center mt-6">
                {error}
              </div>
              <Button
                text={t('SignUp.signUp')}
                onClick={handleSubmit}
                isLoading={loader}
                disabled={loader}
              />
            </>
          ) : (
            <>
              <div className="text-purple text-l">
                A password reset email has been sent. Please check your inbox.
              </div>
            </>
          )}
          <div className="mt-8 text-13 text-grey flex flex-col md:flex-row gap-1 items-center">
            {t('SignUp.alreadyHaveAccount')}
            <span
              className="text-purple font-bold text-base cursor-pointer"
              onClick={() => navigate(URL_MAPPING.LOGIN)}
            >
             {t('SignUp.signIn')}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;