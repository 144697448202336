import React from "react";
import WorkStatsCard from "../../components/WorkStatsCard";
import { useTranslation } from "react-i18next";


interface ReportProps{
  employeeSchedule:any
}

const Report: React.FC<ReportProps> = ({employeeSchedule}) => {
  const { t } = useTranslation();

  return (
    <div className=" overflow-auto h-auto md:h-[calc(100vh-150px)]">
      <div className="grid grid-cols-2 gap-4 p-4">
        <WorkStatsCard title="Working days" workingDays={employeeSchedule?.working_days +' Days'} />
        <WorkStatsCard title="Total Working Time" workingDays={employeeSchedule?.total_working_time} />
        <WorkStatsCard title="Total visits" workingDays={employeeSchedule?.total_visits} />
        <WorkStatsCard title="Successful visits" workingDays={employeeSchedule?.successful_visits}/>
        <WorkStatsCard title="Failed visits" workingDays={employeeSchedule?.failed_visits} />
        <WorkStatsCard title="Driving time" workingDays={employeeSchedule?.driving_time} />
        <WorkStatsCard title="Driving distance" workingDays={employeeSchedule?.driving_distances + ' km'} />
        <WorkStatsCard title="Break time" workingDays={employeeSchedule?.break_time} />
      </div>
    </div>
  );
}

export default Report;